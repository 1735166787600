import React, { useState } from 'react';
import { TextField, Button, Paper, Select, Typography } from '@mui/material';
import IngredientsList from './IngredientsList.js'; // Votre composant personnalisé
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import { editrecipe } from '../../utils/utils.js';
import { useData, DataProvider } from '../../DataProvider.js';
import { packaging_list } from './packaging.js';
import { Checkbox, ListItemText } from '@mui/material';
import { useLocation } from 'react-router-dom';
const RecipeForm = () => {
  let loc = useLocation()
  let recipe2Edit = loc.state.recipe

  const [title, setTitle] = useState('');
  const [ingredients, setIngredients] = useState([]);
  const [type, setType] = useState('maincourse');
  const [nbportion, setNbportion] = useState(0);
  const [packaging, setPackaging] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [recipeReady, setRecipeReady] = React.useState(false);
  const [recipe, setRecipe] = React.useState({'Nutriscore':'C', 'Ecoscore':'B', 'Footprint': 1050});
  const [processingError, setProcessingError] = React.useState(false)
  const [ingredients2edit, setIngredients2edit] = React.useState([])
  const { restaurant, client,loadDataForRestaurant, accessToken } = useData();

  //use data
  const handleIngredientChange = (newIngredients) => {
    setIngredients(newIngredients);
  };

  //Load recipe to edit at the beginning
  React.useEffect(() => {
    if(recipe2Edit){
      setTitle(recipe2Edit.Name)
      setIngredients2edit(recipe2Edit.Ingredients)
      setNbportion(recipe2Edit.Nb_portion)
      setType(recipe2Edit.Type)
      setPackaging(recipe2Edit.Packaging)
    }
  }, [recipe2Edit])
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    // Validez et envoyez les données
    // For each ingredient replace null Code_AGB by ""
    let ingredientsNoNull = ingredients.map(ingredient => {
      if(ingredient.Code_AGB === null){
        ingredient.Code_AGB = ""
      }
      return ingredient
    })
    let recipe = {
      "id" : recipe2Edit.id,
      "client": restaurant,
      "Name" : title,
      "Type" : type,
      "Nb_portion" : nbportion,
      "Packaging" : packaging,
      "Company" : "",
      "restaurant" : restaurant,
      "Ingredients" : ingredientsNoNull,
      "Status" : "valid"
    }
    
    editrecipe(restaurant, recipe, accessToken)    
    .then((response) => {
      if(!response){
        setProcessingError(true)
      }else{
        loadDataForRestaurant(restaurant,client);
        setProcessingError(false)
        setRecipeReady(true);
        setRecipe(response);
        setLoading(false);
      }
    })
  };

  const handleUpdate = (key, value) => {
    // Mettez à jour les données du formulaire
    if (key === 'nbportion') {
      setNbportion(value);
    }
    if (key === 'type') {
      setType(value);
    }
    if (key === 'Packaging') {
      setPackaging(value);
    }
  }
  return (
    <Box>
      <Paper sx={{ maxWidth: '100%', overflowX: 'auto', padding: "5px", display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
        <Typography variant="h6" style={{marginBottom:15}}>Informations générales sur la recette</Typography>
        <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end', marginBottom:30, marginTop:10 }}>
        <div>
        </div>
        <div style={{display: "flex", flexDirection: "column"}}>
          <Typography variant="h8" style={{marginBottom:5}}> Nom de la recette<span style={{color:"red"}}>*</span></Typography>
          <TextField 
            label="Nom de la recette" 
            value={title} 
            onChange={(e) => setTitle(e.target.value)} 
            fullWidth 
          />
        </div>
        
        <div style={{display: "flex", flexDirection: "column"}}>
          <Typography variant="h8" style={{marginBottom:5}}>Nombre de portions<span style={{color:"red"}}>*</span></Typography>
          <TextField
            type="number"
            value={nbportion}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10);
              if (value >= 0 || e.target.value === "") { // Accepter seulement les valeurs positives ou une entrée vide
                handleUpdate('nbportion', e.target.value);
              }
            }}
            inputProps={{ min: 0 }} // Utilisation de min pour bloquer les valeurs négatives
            fullWidth
          />
        </div>

        <div style={{display: "flex", flexDirection: "column"}}>
          <Typography variant="h8" style={{marginBottom:5}}>Type de la recette</Typography>
          <Select
            value={type}
            label="Type de recette"
            onChange={(e) => handleUpdate('type', e.target.value)}
            fullWidth
            displayEmpty
          >
            <MenuItem value="starter">Entrée</MenuItem>
            <MenuItem value="maincourse">Plat</MenuItem>
            <MenuItem value="dessert">Dessert</MenuItem>
          </Select>

          
        </div>
        <div style={{display: "flex", flexDirection: "column"}}>
          <Typography variant="h8" style={{marginBottom:5}}>Emballage</Typography>
          <Select
            label="Emballage"
            multiple
            value={packaging}
            onChange={(e) => handleUpdate('Packaging', e.target.value)}
            renderValue={(selected) => selected.join(',\n')}
          >
            {packaging_list.map((pack) => (
              <MenuItem key={pack} value={pack}>
                <Checkbox checked={packaging.includes(pack)} />
                <ListItemText primary={pack} />
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      <Typography variant="h6" className='ingredientTitle'>Liste des ingrédients</Typography>
      <IngredientsList ingredients2Edit={ingredients2edit} onIngredientsChange={handleIngredientChange} />
      <div style={{textAlign:"center"}}>
      <Button disabled={ loading} type="submit" variant="contained" color="primary" style={{ marginTop: '20px'}}>
        Valider les modifications          
        {loading && (
              <>
                <CircularProgress color='info'/>
              </>
            )}
      </Button>
      </div>

        </form>

      </Paper>
      <Box style={{marginTop:20}}>
      {
        recipeReady && (      
        <Alert severity="success">
          <AlertTitle>Edition et traitement de la recette reussie !</AlertTitle>
          <h2>Vos indicateurs:</h2>
          <Box>
            <img src={`../nutriscore/${recipe['Nutriscore']}.svg`}
            alt={`${recipe['Nutriscore']}`}
            height="20%"
            width="20%" />
              <img  src={`../ecoscore/${recipe['Ecoscore']}.svg`}
              alt={`${recipe['Ecoscore']}`}
              height="8%"
              width="8%" />
            <p><strong>Empreinte carbone de {parseInt(recipe['Footprint']/recipe['Nb_portion'])}gCO2eq/portion</strong></p>
          </Box>
        </Alert>)
      }

      {
        processingError && (      
        <Alert severity="error">
          <AlertTitle>Erreur lors de l'edition de la recette!</AlertTitle>
          Une erreur est survenue à l'edition de la recette dans la base de données<br/>
          Essayez de nouveau, si cela ne fonctionne toujours pas contactez le support (rubrique Aide & Contact).
        </Alert>)
      }
      </Box>
    </Box>
    
  );
};

export default RecipeForm;
