import React, { useEffect, useState } from 'react';
import { Checkbox, ListItemText, TextField, Select, MenuItem, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import { labels_list } from './labels';
const code_AGB_except = ["25948", "25930", "25947"]

const LineTable = ({ ingredient, onUpdate, onDelete }) => {
  const [query, setQuery] = useState({ "Nom_du_Produit_en_Français": '' });
  const [data, setData] = useState([{ "Nom_du_Produit_en_Français": '' }]);
  const [hideResults, setHideResults] = useState(false);

  const handleUpdate = (key, value) => {

    
    if (key === 'Name') {
      onUpdate({ ...ingredient, [key]: value['Nom_du_Produit_en_Français'], 
                "Code_AGB": value['Code_AGB'], 
                "Score_unique_EF": value["Score_unique_EF"],
                "Group": value["Groupe_d'aliment"],
                "Code_CIQUAL": value["Code_CIQUAL"],
                "DQR": value["DQR"],
                "Sous-groupe_d'aliment": value["Sous-groupe_d'aliment"],
                "Ingredient_name_found": "",
                "Changement_climatique": value["Changement_climatique"]
              });
    } else {
      onUpdate({ ...ingredient, [key]: value });
    }
  };

  const handleQueryChange = async (ingredient_name_query) => {
    let exception = false
    // tester si l'ingredient en minuscule est égal à kasha
    if(ingredient_name_query.toLowerCase() === "kasha"){
      exception = true
      ingredient_name_query = "Sarrasin entier, cru";

    }
    setQuery({ "Nom_du_Produit_en_Français": ingredient_name_query });
    // Implement your search logic here
    const base = "https://data.ademe.fr/data-fair/api/v1/datasets/agribalyse-31-synthese/lines?";
    const select = "select=Nom_du_Produit_en_Français,Code_AGB,Groupe_d'aliment,Code_CIQUAL,DQR,Sous-groupe_d'aliment,Score_unique_EF,Changement_climatique";
    const column_filter = `&q_mode="complete"&q=Nom_du_Produit_en_Français:"${ingredient_name_query}" |`;
    const query = base + select + column_filter;

    fetch(query)
      .then(response => response.json())
      .then(res => {
        const ingredients = [];
        setData([]);
        if(exception){
          res.results[0]["Nom_du_Produit_en_Français"] = "Kasha";
        }
        for (let i = 0; i < res.results.length; i++) {
          let group = res.results[i]["Groupe_d'aliment"];
          if (group !== "entrees et plats composes" && group !== "entrées et plats composés") {
            ingredients.push(res.results[i]);
          }else{
            if(code_AGB_except.includes(res.results[i]["Code_AGB"])){
              ingredients.push(res.results[i]);
            }
          }
        }
        setData(ingredients);
        setHideResults(false);
      })
      .catch(error => console.error(error));
  };

  return (
    <TableRow>
      <TableCell>
        <Autocomplete
          value={ingredient["Name"]}
          options={data}
          getOptionLabel={(option) => option['Nom_du_Produit_en_Français'] || ingredient["Name"]}
          onChange={(event, newValue) => {
            handleUpdate('Name', newValue);
          }}
          onInputChange={(event, newInputValue) => { handleQueryChange(newInputValue); }}
          isOptionEqualToValue={(option, value) => option['Nom_du_Produit_en_Français'] === value['Nom_du_Produit_en_Français']}
          sx={{ minWidth: 250 }}
          renderInput={(params) => <TextField {...params} label={`ingrédient`} />}
        />
      </TableCell>
      <TableCell>
        <TextField
          type="number"
          value={ingredient["Quantity"]}
          onChange={(e) => {
            const value = e.target.value;
            // Accepter les nombres positifs ou une entrée vide
            if (value === "" || parseFloat(value) >= 0) {
              handleUpdate('Quantity', value === "" ? "" : parseFloat(value));
            }
          }}
          inputProps={{ min: 0, step: 0.001 }} // Utilisation de step pour permettre les valeurs décimales
          sx={{ minWidth: '80px' }}
        />
      </TableCell>
      <TableCell>
        <Select
          value={ingredient["Unit"]}
          onChange={(e) => handleUpdate('Unit', e.target.value)}
        >
          <MenuItem value="l">L</MenuItem>
          <MenuItem value="kg">Kg</MenuItem>
          <MenuItem value="pce">pièce(s)</MenuItem>

        </Select>
      </TableCell>
      <TableCell>
        <Select
          multiple
          value={ingredient["Labels"]}
          onChange={(e) => handleUpdate('Labels', e.target.value)}
          renderValue={(selected) => {
                let s = selected.join(',\n')
                //remove first comma
                if(s.charAt(0) === ','){
                  return s.slice(1)
                }
                return s
              }
          }
        >
          {labels_list.map((label) => (
            <MenuItem key={label} value={label}>
              <Checkbox checked={ingredient['Labels'].includes(label)} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell>
        <Select
          value={ingredient["Origin"]}
          onChange={(e) => handleUpdate('Origin', e.target.value)}
        >
          <MenuItem value="local">Local</MenuItem>
          <MenuItem value="france">France</MenuItem>
          <MenuItem value="ue">Europe</MenuItem>
          <MenuItem value="monde">Hors Europe</MenuItem>
        </Select>
      </TableCell>
      <TableCell>
        <Select
          value={ingredient["Cooking"]}
          onChange={(e) => handleUpdate('Cooking', e.target.value)}
        >
          <MenuItem value="four electrique">Four</MenuItem>
          <MenuItem value="plaque vitroceramique">Plaque de cuisson</MenuItem>
          <MenuItem value="non cuit">Non cuit</MenuItem>
        </Select>
      </TableCell>
      <TableCell>
        <Select
          value={ingredient["Storage"]}
          onChange={(e) => handleUpdate('Storage', e.target.value)}
        >
          <MenuItem value="froid_pos">Froid positif</MenuItem>
          <MenuItem value="ambiant">Température ambiante</MenuItem>
          <MenuItem value="froid_neg">Froid négatif</MenuItem>
        </Select>
      </TableCell>
      <TableCell>
        <Button onClick={() => onDelete(ingredient["id"])}>Supprimer</Button>
      </TableCell>
    </TableRow>
  );
};

const IngredientsList = ({ ingredients2Edit, onIngredientsChange }) => {
  const [ingredients, setIngredients] = useState([]);

  useEffect(() => {
    const ingredients2EditReadyToEdit = ingredients2Edit.map((ingredient) => {
      const labels2Edit = typeof ingredient["Labels"] === "string"
        ? ingredient["Labels"].replace("[", "").replace("]", "").split(",")
        : ingredient["Labels"];
      ingredient["id"] = Date.now() + Math.random();
      return { ...ingredient, "Labels": labels2Edit};
    });
    setIngredients(ingredients2EditReadyToEdit);
  }, [ingredients2Edit]);

  const addLine = () => {
    setIngredients([
      ...ingredients,
      {
        "id": Date.now(),
        "Name": '',
        "Quantity": '',
        "Unit": 'L',
        "Labels": [],
        "Origin": '',
        "Cooking": '',
        "Storage": ''
      },
    ]);
  };

  const deleteLine = (id) => {
    const newIngredients = ingredients.filter((ingredient) => ingredient["id"] !== id);
    setIngredients(newIngredients);
    onIngredientsChange(newIngredients); // Ensure parent state is updated
  };

  const updateLine = (updatedIngredient) => {
    const newIngredients = ingredients.map((ingredient) => {
      if (ingredient["id"] === updatedIngredient["id"]) {
        return updatedIngredient;
      }
      return ingredient;
    });
    setIngredients(newIngredients);
    onIngredientsChange(newIngredients); // Ensure parent state is updated
  };

  return (
    <div style={{ maxWidth: '100%' }}>
      <Alert severity="info">Les colonnes indiquées par <span style={{ color: "red" }}>*</span> sont obligatoires. </Alert>
      <Table style={{ maxWidth: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>Nom<span style={{ color: "red" }}>*</span></TableCell>
            <TableCell>Quantité<span style={{ color: "red" }}>*</span></TableCell>
            <TableCell>Unité<span style={{ color: "red" }}>*</span></TableCell>
            <TableCell>Labels</TableCell>
            <TableCell>Provenance</TableCell>
            <TableCell>Cuisson</TableCell>
            <TableCell>Conservation</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ingredients.map((ingredient) => (
            <LineTable
              key={ingredient["id"]}
              ingredient={ingredient}
              onUpdate={updateLine}
              onDelete={deleteLine}
            />
          ))}
        </TableBody>
      </Table>
      <div style={{ textAlign: "center" }}>
        <Button onClick={addLine}>Ajouter un ingrédient</Button>
      </div>
    </div>
  );
};

export default IngredientsList;
