import React from "react";
import "./LoginPage.css"; // Importer le fichier CSS pour le style
import Button from '@mui/material/Button';
import "@fontsource/josefin-sans"; // Defaults to weight 400
import { useMsal } from '@azure/msal-react';
import { useState } from "react";
import Pricing from "../Pricing/Pricing";


function goToCalendy() {
    // Obtenir la date au format {year}-{month}
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}`;
    const urlDemo = `https://calendly.com/elisa-jourde-foodprint/30min?month=${formattedDate}`;
    window.open(urlDemo, "_blank");
}

const LoginPage = () => {
  const { instance } = useMsal();
  const [idToken, setIdToken] = useState("");
  const [SignUpMode, setSignUpMode] = useState(false);
  // Configuration pour rediriger vers le user flow d'inscription
  const Login = async () => {
      try {
          let {idToken} = await instance.loginPopup();
          setIdToken(idToken);
      } catch (error) {
          console.error(error);
      }
  }

  return (
    <div >

      {
        SignUpMode === true ?  <Pricing /> : (
          <div class="container">
            <div class="left-half">
                <img src="illustration_login.png" alt="illustration_login.png"/>
            </div>
            <div class="right-half">
                <img src="logo_plateforme-cropped.svg" alt="logo foodprint" className="logo" />
                <div class="centered-rectangle">
                  <div class="rectangle-content">
                  <h1 className="welcome-text">Envie d'accèder à la plateforme ?</h1>

                    <Button
                        variant="contained" 
                        style={{ backgroundColor: '#37A18D', color: '#FFFFFF', borderRadius:20, fontFamily: "josefin sans"}}      
                        title="Sign In"
                        onClick={() => Login()}
                      >
                          Connexion
                    </Button>
                    {
                      /*
                    <Button
                        variant="contained" 
                        style={{ backgroundColor: '#37A18D', color: '#FFFFFF', borderRadius:20, fontFamily: "josefin sans"}}      
                        title="Sign In"
                        onClick={() => SignUp()}
                      >
                          Inscription
                    </Button>
                      */
                    }

                    <h1>Besoin d'en savoir plus ?</h1>
                  <Button onClick={goToCalendy} variant="contained" style={{ backgroundColor: '#37A18D', color: '#FFFFFF', borderRadius: 20,  fontFamily: "josefin sans" }}>Reserver une démonstration</Button>
                  </div>


                  
                </div>
            </div>
          </div>
        )
      }
    </div>

  );
};

export default LoginPage;
